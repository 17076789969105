export default function theEye() {
    const hotspotImage = document.querySelector(
        '.wp-block-getwid-image-hotspot'
    );
    function getOffset(div) {
        const rect = div.getBoundingClientRect();

        const offset = {
            top: rect.top + window.scrollY,
            left: rect.left + window.scrollX,
        };
        return offset;
    }
    function drawline(line, div1, div2) {
        const x1 = getOffset(div1).left + div1.offsetWidth / 2;
        const y1 = getOffset(div1).top + div1.offsetHeight / 2;
        const x2 = getOffset(div2).left + div2.offsetWidth - 25;
        const y2 = getOffset(div2).top + div2.offsetHeight / 2;
        line.setAttribute('x1', x1);
        line.setAttribute('y1', y1);
        line.setAttribute('x2', x2);
        line.setAttribute('y2', y2);
    }
    if (hotspotImage) {
        const data = JSON.parse(hotspotImage.dataset.imagePoints);

        const container = document.querySelector('.theEyeContent');

        const eyeNav = document.createElement('DIV');
        eyeNav.classList.add('eyeNav', 'mb-3');
        container.appendChild(eyeNav);

        const prev = document.createElement('BUTTON');
        prev.innerText = 'Voriges';
        eyeNav.appendChild(prev);

        const next = document.createElement('BUTTON');
        next.innerText = 'Nächtes';
        eyeNav.appendChild(next);

        const eyeTitle = document.createElement('H1');
        eyeTitle.innerHTML = data[0].title;
        container.appendChild(eyeTitle);
        const eyeText = document.createElement('P');
        eyeText.innerHTML = data[0].content;
        container.appendChild(eyeText);

        localStorage.setItem('currentEyeId', 0);

        const line = document.getElementById('line');

        const spots = document.querySelectorAll(
            '.wp-block-getwid-image-hotspot__dot'
        );
        spots.forEach((spot) => {
            spot.addEventListener('click', () => {
                eyeTitle.innerHTML = data[spot.dataset.pointId].title;
                eyeText.innerHTML = data[spot.dataset.pointId].content;
                drawline(line, spot, container);
            });
        });

        drawline(line, spots[0], container);

        prev.addEventListener('click', () => {
            let currentId = localStorage.getItem('currentEyeId');
            const prevId = --currentId;
            if (prevId >= 0) {
                eyeTitle.innerHTML = data[prevId].title;
                eyeText.innerHTML = data[prevId].content;
                localStorage.setItem('currentEyeId', prevId);
                drawline(line, spots[prevId], container);
            } else {
                eyeTitle.innerHTML = data[data.length - 1].title;
                eyeText.innerHTML = data[data.length - 1].content;
                localStorage.setItem('currentEyeId', data.length - 1);
                drawline(line, spots[data.length - 1], container);
            }
        });

        next.addEventListener('click', () => {
            let currentId = localStorage.getItem('currentEyeId');
            const nextId = ++currentId;
            if (data.length > nextId) {
                eyeTitle.innerHTML = data[nextId].title;
                eyeText.innerHTML = data[nextId].content;
                localStorage.setItem('currentEyeId', nextId);
                drawline(line, spots[nextId], container);
            } else {
                eyeTitle.innerHTML = data[0].title;
                eyeText.innerHTML = data[0].content;
                localStorage.setItem('currentEyeId', 0);
                drawline(line, spots[0], container);
            }
        });

        window.onresize = () => {
            const currentId = localStorage.getItem('currentEyeId');
            drawline(line, spots[currentId], container);
        };
    }
}
