/* eslint-disable no-unused-vars */
import '@fancyapps/fancybox';
import './plugins/inView';
import Swiper, {
    Navigation,
    Pagination,
    Lazy,
    EffectFade,
    Autoplay,
} from 'swiper';
import Offcanvas from 'bootstrap/js/dist/offcanvas';

import mapBox from './inc/hmMaps';
import dataStore from './inc/dataStore';
import observeIntersection from './inc/observeIntersection';
import scriptLoader from './inc/scriptLoader';
import devAt from './inc/devAt';
import theEye from './inc/theEye';
import contentTeaser from './inc/contentTeaser';

Swiper.use([Navigation, Pagination, Lazy, EffectFade, Autoplay]);

const LazyLoad = require('vanilla-lazyload');

window.jQuery = jQuery;
window.$ = jQuery;

const headerSlider = () => {
    const headerSliderEl = document.getElementById('headerSlider_js');
    new Swiper(headerSliderEl, {
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
};

const imgSlider = () => {
    const imgSliderEl = document.getElementById('imgSlider_js');
    new Swiper(imgSliderEl, {
        speed: 600,
        spaceBetween: 30,
        navigation: {
            nextEl: '.imgSliderButtonNext',
            prevEl: '.imgSliderButtonPrev',
        },
    });
};

const teamImageSlider = () => {
    const teamSliderEl = document.getElementById('teamImageSlider_js');
    new Swiper(teamSliderEl, {
        // effect: 'fade',
        speed: 600,
        draggable: false,
        allowTouchMove: false,
        touchRatio: 0,
        fadeEffect: {
            crossFade: true,
        },
        navigation: {
            nextEl: '.teamButtonNext',
            prevEl: '.teamButtonPrev',
        },
    });
};

const teamTextSlider = () => {
    const teamSliderEl = document.getElementById('teamTextSlider_js');
    new Swiper(teamSliderEl, {
        effect: 'fade',
        speed: 600,
        draggable: false,
        allowTouchMove: false,
        touchRatio: 0,
        autoHeight: true,
        fadeEffect: {
            crossFade: true,
        },
        navigation: {
            nextEl: '.teamButtonNext',
            prevEl: '.teamButtonPrev',
        },
    });
};

const illnessSlider = () => {
    const illnessSliderEl = document.getElementById('illnessSlider_js');
    new Swiper(illnessSliderEl, {
        spaceBetween: 40,
        navigation: {
            nextEl: '.illnessButtonNext',
            prevEl: '.illnessButtonPrev',
        },
    });
};

window.setTheme = (event, theme) => {
    const buttons = event.target.parentElement.children;
    // eslint-disable-next-line no-restricted-syntax
    for (const btn of buttons) {
        btn.classList.toggle('active');
    }
    document.documentElement.className = theme;
    localStorage.setItem('beFontsize', theme);
};

const setFontSize = () => {
    const beFontsize = localStorage.getItem('beFontsize');
    if (beFontsize) {
        document.documentElement.className = beFontsize;
        const buttons = document.querySelectorAll(`.${beFontsize}_js`);
        // eslint-disable-next-line no-restricted-syntax
        for (const btn of buttons) {
            btn.classList.add('active');
        }
    } else {
        localStorage.setItem('beFontsize', 'fontnormal');
        const buttons = document.querySelectorAll('fontnormal_js');
        // eslint-disable-next-line no-restricted-syntax
        for (const btn of buttons) {
            btn.classList.add('active');
        }
    }
};

const showServices = () => {
    $('.servicesGrid').each((i, el) => {
        const id = $(el).attr('id');

        const servicesGrid = $(`#${id}`);
        const showServicesBtn = $(`#${id} .showAllServices__js`);

        console.log(id);
        console.log(servicesGrid);

        if (showServicesBtn && servicesGrid) {
            showServicesBtn.on('click', (e) => {
                if (servicesGrid.hasClass('showall')) {
                    servicesGrid.removeClass('showall');
                } else {
                    servicesGrid.addClass('showall');
                    e.target.style.display = 'none';
                }
            });
        }

    });
};

const video = document.getElementById('headerVideo__js');
const muteButton = document.getElementById('toggleVideoSound__js');
if (muteButton) {
    const muteSvgs = muteButton.querySelectorAll('span');
}

const videoMute = () => {
    muteButton.addEventListener('click', () => {
        if (video.muted === true) {
            video.muted = false;
        } else {
            video.muted = true;
        }
        muteButton.classList.toggle('muted');
    });
};

/* Author: herrlich media */
$(document).ready(() => {
    /* observe element intersection example +++++++++++++++++++++++++++++*/

    if (document.body.classList.contains('home')) {
        videoMute();
    }

    setFontSize();

    theEye();

    contentTeaser();

    showServices();

    function initMap() {
        scriptLoader(
            // eslint-disable-next-line no-undef
            [`${themeVars.assetsUrl}dist/js/mapbox/mapbox-gl.js`],
            mapBox
        );
    }

    observeIntersection('#map', initMap, true);

    lazyloadUpdate();

    // Eventlistener
    $('.pageLine__scrollerContainer').on('click', () => {
        const videoHero = $('#videoHero');
        const videoHeroOffset = videoHero.height() + videoHero.offset().top;

        dataStore.html.stop().animate({
            scrollTop: videoHeroOffset - 62
        }, 300);
    });

    // FANCYBOX
    $('[data-fancybox]').fancybox({
        afterLoad() {
            dataStore.smoothDisabled = true;
        },
        beforeClose() {
            dataStore.smoothDisabled = false;
        },
    });

    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    $(window).scroll(() => {
        dataStore.docScroll = $(window).scrollTop();
    });

    /* developed at ++++++++++++++++++++++++++++++++++++++++++*/
    devAt();
}); /* ready end ++++++++++++++++++++++++++++++++++++++++++*/

$(window).resize(() => {
    dataStore.docWidth = $(document).width();
    dataStore.docHeight = $(document).height();
    dataStore.winHeight = $(window).height();

    $(window).trigger('scroll');

    contentTeaser();

    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});

$(window).on('load', () => {
    headerSlider();
    imgSlider();
    teamImageSlider();
    teamTextSlider();
    illnessSlider();

    $('#navToggle').click(() => {
        $('#navToggle').toggleClass('open');
    });

    /* In View  ++++++++++++++++++++++++++++++++++++++++++*/
    $('.fadeIn').one('inview', (e, visible) => {
        if (visible === true) {
            $(e.currentTarget).not('.vis').addClass('vis');
        }
    });

    $('.fadeInRow').one('inview', (e, visible) => {
        if (visible === true) {
            $(e.currentTarget)
                .find('> div, > li, > p')
                .not('.vis')
                .each((i, el) => {
                    setTimeout(() => {
                        $(el).addClass('vis');
                    }, 150 * i);
                });
        }
    });
});

// Lazyload Update
const lazyloadUpdate = function lazyloadUpdate() {
    if (dataStore.lazyLoadInstance !== '') {
        dataStore.lazyLoadInstance.update();
    }
};

/* Webp Support Detection  ++++++++++++++++++++++++++++++++++++++++++*/
async function supportsWebp() {
    if (!window.self.createImageBitmap) return false;

    const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
    const blob = await fetch(webpData).then((r) => r.blob());
    return createImageBitmap(blob).then(
        () => true,
        () => false
    );
}

// Add Webp to Background Imgaes when supported
(async() => {
    if (await supportsWebp()) {
        dataStore.body.addClass('webp');
        const lazyBg = document.querySelectorAll('[data-bg]');
        lazyBg.forEach((element) => {
            let { bg } = element.dataset;
            if (
                bg.match(new RegExp('.*(.jpg|.jpeg|.png)'))
                && !bg.includes('.webp')
                && !bg.includes('cdninstagram')
            ) {
                bg = `${bg}.webp`;
                element.setAttribute('data-bg', bg);
            }
        });
    }

    /* lazy load  ++++++++++++++++++++++++++++++++++++++++++*/
    dataStore.lazyLoadInstance = new LazyLoad({
        elements_selector: '.lazy',
    });
    lazyloadUpdate();
})();
