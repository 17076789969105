class DataStore {
    constructor() {
        /* scrolling ++++++++++++++++++++++++++++++++++++++++++*/
        this.docScroll = '';
        this.win = $(window);
        this.body = $('body');
        this.html = $('html');
        /* window ++++++++++++++++++++++++++++++++++++++++++*/

        this.docWidth = $(document).width();
        this.docHeight = $(document).height();
        this.winHeight = $(window).height();

        /* non editable ++++++++++++++++++++++++++++++++++++++++++*/
        this.endOfResize = false;

        /* browser ++++++++++++++++++++++++++++++++++++++++++*/

        this.isIe = false;
        this.isFirefox = false;
        this.isSafari = false;
        this.isNoIe = false;
        this.touch = false;
        this.touchBreakPoint = 768;

        /* ids ++++++++++++++++++++++++++++++++++++++++++*/
        this.globalIds = JSON.parse(window.wp_urls.globalIds);

        /* fancybox ++++++++++++++++++++++++++++++++++++++++++*/
        this.fancyBoxOnceOpened = false;

        /* smoothscrolling ++++++++++++++++++++++++++++++++++++++++++*/
        this.scrollEventListener = 'wheel';
        this.smoothDisabled = false;

        /* masonry ++++++++++++++++++++++++++++++++++++++++++*/
        this.grid = '';

        /* lazyload ++++++++++++++++++++++++++++++++++++++++++*/
        this.lazyLoadInstance = '';

        /* Map ++++++++++++++++++++++++++++++++++++++++++*/
        this.mapMarkers = [];
        this.map = '';
    }
}

export default (new DataStore());
