export default function contentTeaser() {
    if (window.matchMedia('(min-width: 1200px)').matches) {
        const contentTeaserContainer = document.getElementById('contentTeaser');
        if (contentTeaserContainer) {
            // contentTeaserContainer.style.height = `${
            //     contentTeaserContainer.offsetHeight + 100
            // }px`;
            const teasers = contentTeaserContainer.querySelectorAll('.col-12');

            teasers[0].classList.add('active');
            teasers.forEach((teaser) => {
                teaser.addEventListener('click', (e) => {
                    teasers.forEach((tsea) => {
                        tsea.classList.remove('active');
                    });
                    e.target.parentElement.classList.add('active');
                });
            });
        }
    }
}
